import React from 'react'
import {Container} from 'reactstrap'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1 className="display-4">Page Not Found</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
